import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";

const Page = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title="Automated GitLab Backup Solution"
      description="Automate your GitLab backups securely. Connect your GitLab account, schedule when and where to store your backups."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">GitLab Backup</span> for all your repositories.</>)}
      subTitle={(
        <>Backup and restore your GitLab repos, accounts, issues, wiki and all metadata.<br/>
          Trust your GitLab data is always available and stored where you want.
        </>)}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename={`brands/icons/gitlab-ico.png`} alt={`GitLab backup`}  className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your GitLab Backup" urlAtrr={{ sb_source: "website", sb_term: `gitlab-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-gitlab-backup-hero.png" alt="Scheduled GitLab Backup Solution"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                title: (<>Never lose your <span className="text-primary">GitLab</span> data</>),
                description: (
                  <>
                    <p>
                      Connect your GitLab account and enable automated backup of your <span
                      className="link-highlight">repositories</span> together with <span className="link-highlight">merge requests</span>, <span
                      className="link-highlight">projects</span>, <span className="link-highlight">snippets</span>, <span
                      className="link-highlight">issues</span>, etc.
                    </p>
                    <Ui.MiniFaqs items={[
                      {
                        title: 'Your GitLab Backups without code or maintenance',
                        content: (<>
                          Forget creating manual scripts to backup your GitLab data. This requires, time, maintenance and won't offer everything your backups need.<br/>
                          Multi-cloud backups, anomaly detection, multi-channel notifications, team access (...) without one line of code.
                        </>)
                      },
                      {
                        title: 'Store your backups on your favorite cloud storage',
                        content: (<>Leverage our 50+ cloud storage providers integrations to store your GitLab backups. Choose the storage that fits your and own your backups.
                            <Ui.FeatureChecks items={['Bring your own storage', 'SimpleStorage', 'S3 Compatible storage', '50+ Cloud Storage services']} />
                          </>)
                      },
                      {
                        title: 'Backup your GitLab Repositories and everything else!',
                        content: (<>
                          Select the respositories you want to backup and the data type that should be cincluded in your backups. <br/> We support them all!
                          <Ui.FeatureChecks cols="3" items={['Repositories', 'Commits', 'Wiki', 'Projects', 'Issues', 'Snippets']} />
                        </>)
                      },
                    ]} />
                  </>
                ),
                image: (<Ui.Image
                  filename='simplebackups-gitlab-options.png'
                  alt="GitLab backup service"
                  style={{maxWidth: 450}}
                />)
              },
              {
                title: (<><span className="text-primary">Stay compliant</span> with your most critical assets</>),
                description: (
                  <>
                    <p>
                      SimpleBackups is a <span className="link-highlight">security-audited and certified solution</span>. Benefit from the strict European security requirements on all your backups, no matter where you’re
                      based.
                      <br/>

                      <Ui.MiniFaqs items={[
                        {
                          title: 'Team access and role-based permissions',
                          content: (<>Enterprise-ready team authorization, roles, multi-factor authentication and
                            permission granularity.</>)
                        },
                        {
                          title: 'Backup Encryption and end-to-end secured transfers',
                          content: (<>Secure your GitLab backups using AES-256 encryption, even using your own key.</>)
                        },
                        {
                          title: 'Compliance dashboard & audit exports',
                          content: (<>Visualize your backup compliance checklist from a dedicated dashboard. Download
                            your audit reports and
                            documentation. Simplify your ISO, SOC2, HIPPA process with built-in resources.</>)
                        },
                        {
                          title: 'Advanced monitoring features & notifications',
                          content: (<>Detect anomalies and be notified whenever your backups requires attention.
                            <br/>Email, Slack, Discord (...), custom webhook notifications for all your backups and
                            weekly reports for your team.</>)
                        },
                      ]}/>


                      <div className="mt-6">
                        <p className=" font-light text-xs tracking-wider uppercase opacity-75 -mb-2">Ease your Compliance process for:</p>
                        <Ui.FeatureChecks cols="3" items={['ISO27001', 'SOC2', 'HIPAA', 'GDPR', 'ISMS']}/>
                      </div>
                      <Ui.Link arrow="right" to="/security-first/">How we treat your data</Ui.Link>
                    </p>
                  </>
                ),
                image: (
                  <div className="flex flex-col gap-6 items-center justify-center">
                    <Ui.Image
                      filename='simplebackups-app-github-compliance.png'
                      alt="GitHub backup compliance and security features"
                      style={{maxWidth: 450}}
                    />
                    <div className="flex">
                      <div className=" w-32 ">
                        <Ui.Image filename="gdpr-compliant"></Ui.Image>
                      </div>
                      <div className="w-32 ">
                        <Ui.Image filename="iso27001-horizontal.png"></Ui.Image>
                      </div>
                    </div>
                  </div>

                )
              },
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">GitLab Backup <i>as a service</i></Ui.Typography>

          <div className="grid md:grid-cols-12 md:gap-8 gap-4 text-left mt-16">
            <div className="col-span-6 row-span-2 bg-white rounded-xl p-8 pb-0">
              <i className="fad fa-solid fa-clipboard-list-check text-primary text-4xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Flexible Backup Strategy</h3>
              <p className="text-base">
                Define when and how often you want to backup your GitLab data.<br/>
                Setup the <span className="link-highlight">grand-father-father-son backup strategy</span> or any custom
                schedule you need.
                in a few clicks.<br/>
                <span className="link-highlight">Leverage multi-cloud backups</span> and store your data where you want:
                SFTP, S3, Drives, Local storage, etc.
              </p>
              <Ui.Image className="mt-4" filename="simplebackups-app-flexible-storage-schedule.png"></Ui.Image>
            </div>
            <div className="col-span-6 bg-white rounded-xl p-8">
              <i className="fad fa-solid fa-shield-check text-primary text-4xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Disaster Recovery</h3>
              <p className="text-base">
                Worry-free GitLab backup recovery with clear steps and automation: anyone in your team can restore your
                data at any time.<br/>
                No guessing, <span className="link-highlight">pick a backup and restore it in a few clicks</span>.
              </p>
            </div>
            <div className="col-span-6 bg-white rounded-xl p-8">
              <i className="fad fa-fingerprint text-primary text-4xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Compliance & Audit ready</h3>
              <p className="text-base">
                We got you covered with our <span className="link-highlight">compliance dashboard</span> and audit
                exports helping you getting
                GDPR, HIPAA, SOC2, ISO27001 compliant smoothly.
                <br/> SimpleBakcups provides all the tools and documentation you need to pass your audits.
              </p>
            </div>

          </div>


          <div className="grid md:grid-cols-4 md:gap-4 gap-4 text-left mt-16">
            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-4xl mb-4"/>
              <h3 className="text-lg font-bold mb-1 h-8 block">Encrypted GitLab Backups</h3>
              <p className="text-base">
                <span className="link-highlight">All you GitLab backups are encrypted</span> safeguarding it against
                unauthorized access..</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-flask-gear text-primary text-4xl mb-4"/>
              <h3 className="text-lg font-bold mb-1 h-8 block">Backup all GitLab</h3>
              <p className="text-base"><span
                className="link-highlight">Select what to back up and what to exclude</span>. Get full control of what
                each of your backup should contain.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-bell-concierge text-primary text-4xl mb-4"/>
              <h3 className="text-lg font-bold mb-1 h-8 block">Managed GitLab Backups</h3>
              <p className="text-base">No-code, no infrastructure required. Specifically designed to <span
                className="link-highlight">backup GitLab without the hassle</span>.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-arrow-rotate-left text-primary text-4xl mb-4"/>
              <h3 className="text-lg font-bold mb-1 h-8 block">One-click Restoration</h3>
              <p className="text-base">Restore your <span
                className="link-highlight">point-in-time GitLab data</span> using a simple method. You're covered.</p>
            </div>
          </div>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about GitLab backup?</>}
      text={`Find out more and get started with our GitLab Backup guides`}
      tags={['saas-backup', 'saas-gitlab']}
    />

    <Ui.Section color="">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">How it works in 5 steps!</Ui.Typography>
          <div className="grid md:grid-cols-12 md:gap-8 gap-4 text-left">
            <div className="col-span-4 row-span-1 bg-gray-lighter rounded-xl p-8 ">
              <i className="fad fa-solid fa-link text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block"><strong>1.</strong> Connect your GitLab Account</h3>
              <p className="text-base">
                Connect your GitLab account, whether you're using GitLab.com or your self-hosted instance.<br/>
                We support <span className="link-highlight">OAuth2</span> as well as <span
                className="link-highlight">personal access tokens</span> connections.
              </p>
              <Ui.FeatureChecks cols="2" items={['GitLab.com', 'Self-Hosted GitLab']}/>
              <Ui.Image className="mt-4 rounded-xl" filename="simplebackups-gitlab-step1-connect.png"></Ui.Image>
            </div>
            <div className="col-span-4 bg-gray-lighter rounded-xl p-8 pb-0 relative">
              <div></div>
              <i className="fad fa-solid fa-layer-group text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block"><strong>2.</strong> Select your Repositories</h3>
              <p className="text-base">
                Once you account is selected, you'll see the list of all repositories available.
                You can <span className="link-highlight">select</span>, <span className="link-highlight">exclude</span>,
                or include all future repositories using advance selection rules.
              </p>
              <Ui.Image className="mt-6 rounded-xl block " filename="simplebackups-gitlab-step2-repo.png"></Ui.Image>
            </div>
            <div className="col-span-4 bg-gray-lighter rounded-xl p-8">
              <i className="fad fa-solid fa-list text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block"><strong>3.</strong> Select other GitLab Meta data</h3>
              <p className="text-base">
                Backing up your repositories is great, but what about the rest of your data?<br/>
                Select the <span className="link-highlight">additional GitLab data</span> you want to include in your
                backups: <span className="link-highlight">issues</span>, <span
                className="link-highlight">projects</span>, <span className="link-highlight">snippets</span>, etc.
              </p>
              <Ui.Image className="mt-6 rounded-xl block " filename="simplebackups-gitlab-step3-options.png"></Ui.Image>
            </div>


            <div className="col-span-6 bg-gray-lighter rounded-xl p-8">
              <i className="fad fa-solid fa-cloud-arrow-up text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block"><strong>4.</strong> Select your storage</h3>
              <p className="text-base">
                You're almost there! Connect your storage, or use SimpleStorage, our built-in storage solution.<br/>
                You can connect multiple storage to get your backup stored in multiple locations.

                <Ui.FeatureChecks cols="2" items={['Connect any Storage Provider', 'Built-in Backup Storage']}/>
              </p>
              <Ui.Image className="mt-6 rounded-xl block " filename="simplebackups-gitlab-step4-storage.png"></Ui.Image>
            </div>
            <div className="col-span-6 bg-gray-lighter rounded-xl p-8">
              <i className="fad fa-solid fa-clock text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block"><strong>5.</strong> Define when to run the backup</h3>
              <p className="text-base">
                Define when your backup has to run and its retention policy.<br/>
                Schedules are flexible: it can be daily, weekly or actually anything you want.<br/>
                That's it, hit save and your backup is configured!
                <Ui.Image className="mt-6 rounded-xl block "
                          filename="simplebackups-gitlab-step5-schedule.png"></Ui.Image>
              </p>
            </div>

          </div>

          <div className="flex items-center justify-center"><Ui.SignupButton className="mt-6" noSubtitle
                                                                             text="Get Started" urlAtrr={{
            sb_source: "website",
            sb_term: `gitlab`
          }}/></div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>


    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended/>
    <Sections.SectionFaq color="alt" faqs={['what_gitlab_backup_service', 'gitlab_is_there_a_data_limit', 'gitlab_data_type', 'gitlab_server_backup_service', 'gitlab_download', 'gitlab_automated']}/>

  </Layout.Layout>
)

export default Page
